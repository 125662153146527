import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import {
	getMinMaxBudgetPerDeskLimits,
	getMinMaxCapacityLimits,
} from "./filter-functions";

const defaultState = {
	cart: [],
	selectedOfficeType: "Office Type",
	selectedLocation: "",
	selectedFeatures: [],
	selectedSimple: [""],
	viewport: {
		latitude: 51.5134724,
		longitude: -0.090159,
		zoom: 15,
	},
	distancePoint: {
		latitude: 51.5134724,
		longitude: -0.090159,
	},
	numberOfPeople: "default",
	budgetPerDesk: "default",
	options: [],
	filteredOffices: [],
	newServiceOptions: [],
	newAmenitiesOptions: [],
	newOptions: [],
	buttonChecked: false,
	distancePanPoint: {
		latitude: 51.5134724,
		longitude: -0.090159,
	},
	sortedBy: "Sort",
	shortOffices: [],
	distanceAmount: 0.5,
	numberOfDesksCalculator: 1,
	idLocation: "",
	showFilterMobile: "",
};

const CartContext = React.createContext(defaultState);

export default CartContext;

export function CartContextProvider({ children }) {
	const dataGlobal = useStaticQuery(graphql`
		query {
			websiteVersion {
				version
			}
			allAirtableProperties(
				filter: {
					isFloor: { nin: 1 }
					hasFeatures: { eq: 1 }
					hasPhotos: { eq: 1 }
					live: { eq: true }
				}
			) {
				edges {
					node {
						addressLineOne
						desksFrom
						desksTo
						live
						subwayStation1
						subwayStation1DistanceKm
						subwayStation1DurationMins
						subwayStation2
						subwayStation2DistanceKm
						subwayStation2DurationMins
						subwayStation3
						subwayStation3DistanceKm
						subwayStation3DurationMins
						type
						city
						fixedId
						rentPM
						airtableId
						locationLatitude
						locationLongitude
						name
						services
						amenities
						provider
						features
						photos
						description
						floorsAvailable {
							rentPM
							desksTo
							desksFrom
							name
							sqFt
						}
					}
				}
			}
		}
	`);

	const appVersion = dataGlobal.websiteVersion.version;

	const [newOptions, setNewOptions] = useState([]);
	const [newServiceOptions, setNewServiceOptions] = useState([]);
	const [newAmenitiesOptions, setNewAmenitiesOptions] = useState([]);
	const [budgetMinMax, setBudgetMinMax] = useState("none");
	const [capacityMinMax, setCapacityMinMax] = useState("none");

	useEffect(() => {
		const featuresArray = [];
		const servicesArray = [];
		const amenitiesArray = [];

		dataGlobal.allAirtableProperties.edges?.forEach((property) => {
			property.node?.features?.forEach((feature) => {
				if (!featuresArray.includes(feature)) {
					featuresArray.push(feature);
				}
			});
			property.node.services?.forEach((service) => {
				if (!servicesArray.includes(service)) {
					servicesArray.push(service);
				}
			});
			property.node.amenities?.forEach((amenity) => {
				if (!amenitiesArray.includes(amenity)) {
					amenitiesArray.push(amenity);
				}
			});
		});

		setNewOptions(
			featuresArray.map((feature) => ({ label: feature, value: feature }))
		);
		setNewServiceOptions(servicesArray);
		setNewAmenitiesOptions(amenitiesArray);
	}, [dataGlobal.allAirtableProperties.edges]);

	useEffect(() => {
		setBudgetMinMax({
			min: 0,
			max: 150000,
			minPerDesk: 0,
			maxPerDesk: 2000,
			perDesk: false,
		});
		setCapacityMinMax({ min: 0, max: 75, reset: true });
	}, []);

	function usePersistedState(key, defaultValue) {
		const [state, setState] = useState(() => {
			if (typeof window !== "undefined") {
				const persistedState = window.localStorage.getItem(key);
				const storedVersion = localStorage.getItem("appVersion");

				if (storedVersion !== JSON.stringify(appVersion)) {
					localStorage.clear(); // Clear if version is different
					localStorage.setItem("appVersion", JSON.stringify(appVersion));
					return defaultValue;
				}
				return persistedState !== null
					? JSON.parse(persistedState)
					: defaultValue;
			} else {
				return defaultValue;
			}
		});

		useEffect(() => {
			window.localStorage.setItem(key, JSON.stringify(state));
		}, [key, state]);

		return [state, setState];
	}

	const [cart, setCart] = usePersistedState("cart", defaultState.cart);
	const [numberOfDesksCalculator, setNumberOfDesksCalculator] =
		usePersistedState(
			"numberOfDesksCalculator",
			defaultState.numberOfDesksCalculator
		);
	const [selectedOfficeType, setSelectedOfficeType] = usePersistedState(
		"selectedOffice",
		defaultState.selectedOfficeType
	);
	const [selectedLocation, setSelectedLocation] = usePersistedState(
		"locationValue",
		defaultState.selectedLocation
	);
	const [selectedFeatures, setSelectedFeatures] = usePersistedState(
		"selectedFeatures",
		defaultState.selectedFeatures
	);
	const [viewport, setViewport] = usePersistedState(
		"viewportValue",
		defaultState.viewport
	);
	const [distancePoint, setDistancePoint] = usePersistedState(
		"distancePoint",
		defaultState.distancePoint
	);
	const [distancePanPoint, setDistancePanPoint] = usePersistedState(
		"distancePanPoint",
		defaultState.distancePanPoint
	);
	const [numberOfPeople, setNumberOfPeople] = usePersistedState(
		"numberOfPeople",
		defaultState.numberOfPeople
	);
	const [budgetPerDesk, setBudgetPerDesk] = usePersistedState(
		"budgetPerDesk",
		defaultState.budgetPerDesk
	);
	const [buttonChecked, setButtonChecked] = usePersistedState(
		"buttonChecked",
		defaultState.buttonChecked
	);
	const [sortedBy, setSortedBy] = usePersistedState(
		"sortedBy",
		defaultState.sortedBy
	);
	const [shortOffices, setShortOffices] = usePersistedState(
		"shortOffices",
		dataGlobal.allAirtableProperties.edges.slice(0, 5)
	);
	const [distanceAmount, setDistanceAmount] = usePersistedState(
		"distanceAmount",
		defaultState.distanceAmount
	);
	const [filteredOffices, setFilteredOffices] = useState(
		dataGlobal.allAirtableProperties.edges
	);
	const [selectedSimple, setSelectedSimple] = useState(
		defaultState.selectedSimple
	);
	const [idLocation, setIdLocation] = useState(defaultState.idLocation);
	const [showFilterMobile, setShowFilterMobile] = useState(
		defaultState.showFilterMobile
	);

	const stateUpdaters = {
		cart: setCart,
		selectedOffice: setSelectedOfficeType,
		locationValue: (value) =>
			setSelectedLocation(value.split(",").slice(0, 2).join(",")),
		selectedFeatures: setSelectedFeatures,
		viewportValue: setViewport,
		distancePoint: setDistancePoint,
		distancePanPoint: setDistancePanPoint,
		numberOfPeople: setNumberOfPeople,
		budgetPerDesk: setBudgetPerDesk,
		buttonChecked: setButtonChecked,
		sortedBy: setSortedBy,
		shortOffices: setShortOffices,
		distanceAmount: setDistanceAmount,
		idLocation: setIdLocation,
		showFilterMobile: setShowFilterMobile,
		numberOfDesksCalculator: setNumberOfDesksCalculator,
	};

	const settingVal = (value, key) => {
		const updater = stateUpdaters[key];
		if (updater) {
			updater(value);
		}
	};

	const onLocationChange = (lat, lng) => {
		const newDistancePoint = { latitude: lat, longitude: lng };
		const newViewport = { ...viewport, latitude: lat, longitude: lng };
		settingVal(newViewport, "viewportValue");
		settingVal(newDistancePoint, "distancePoint");
	};

	const onLocationChangePan = (viewportNew) => {
		const newDistancePoint = {
			latitude: viewportNew.latitude,
			longitude: viewportNew.longitude,
		};
		const newViewport = {
			...viewport,
			latitude: viewportNew.latitude,
			longitude: viewportNew.longitude,
		};
		settingVal(newViewport, "viewportValue");
		settingVal(newDistancePoint, "distancePanPoint");
	};

	const onLocationChangeScroll = (lat, lng) => {
		const newViewport = { ...viewport, latitude: lat, longitude: lng };
		settingVal(newViewport, "viewportValue");
	};

	function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
		var R = 6371;
		var dLat = deg2rad(lat2 - lat1);
		var dLon = deg2rad(lon2 - lon1);
		var a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) *
				Math.cos(deg2rad(lat2)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d;
	}

	function deg2rad(deg) {
		return deg * (Math.PI / 180);
	}

	const checker = (arr, target) => target.every((v) => arr.includes(v));

	const checkDistance = (lat, lng) => {
		return (
			getDistanceFromLatLonInKm(
				distancePoint.latitude,
				distancePoint.longitude,
				lat,
				lng
			) *
				0.621371 <=
			distanceAmount
		);
	};

	const filterOfficesOnBudget = (property) => {
		const {
			node: { desksTo, desksFrom, rentPM, floorsAvailable },
		} = property;

		if (floorsAvailable !== null) {
			if (!budgetPerDesk.perDesk) {
				return floorsAvailable.some(
					(e) => budgetPerDesk.max >= e.rentPM && budgetPerDesk.min <= e.rentPM
				);
			} else if (budgetPerDesk.perDesk) {
				return floorsAvailable.some(
					(e) =>
						Math.min(e.desksTo, numberOfPeople.max) *
							budgetPerDesk.maxPerDesk >=
							e.rentPM &&
						Math.min(e.desksFrom, numberOfPeople.min) *
							budgetPerDesk.minPerDesk <=
							e.rentPM
				);
			}
		} else {
			if (budgetPerDesk.perDesk) {
				return (
					budgetPerDesk.minPerDesk <= rentPM &&
					budgetPerDesk.maxPerDesk >= rentPM
				);
			} else if (!budgetPerDesk.perDesk) {
				const upperCapacity = Math.min(desksTo, numberOfPeople.max);
				const lowerCapacity = Math.max(desksFrom, numberOfPeople.min);

				const upperCapacityRentPM = upperCapacity * rentPM;
				const lowerCapacityRentPM = lowerCapacity * rentPM;

				return (
					budgetPerDesk.min <= lowerCapacityRentPM &&
					budgetPerDesk.max >= lowerCapacityRentPM
				);
			}
		}
	};

	const filterOfficesOnCapacity = (property) => {
		const {
			node: { desksFrom, desksTo },
		} = property;
		if (numberOfPeople.min > 0) {
			return numberOfPeople.min >= desksFrom && numberOfPeople.min <= desksTo;
		} else return true;
	};

	const filterOfficesOnType = (property) => {
		const {
			node: { type },
		} = property;

		return (
			type === selectedOfficeType ||
			selectedOfficeType === "Any" ||
			selectedOfficeType === "Office Type"
		);
	};

	const filterOfficesDistance = (property) => {
		return (
			checkDistance(
				property.node.locationLatitude,
				property.node.locationLongitude
			) && checker(property.node.features, selectedFeatures)
		);
	};

	const allFilters = (property) => {
		return (
			filterOfficesOnType(property) &&
			filterOfficesDistance(property) &&
			filterOfficesOnCapacity(property) &&
			filterOfficesOnBudget(property)
		);
	};

	const OnAddToCart = (val) => {
		settingVal([...cart, val], "cart");
	};

	const OnRemoveFromCart = (val) => {
		settingVal(
			cart.filter((el) => el.airtableId !== val.airtableId),
			"cart"
		);
	};

	useEffect(() => {
		setSelectedSimple(selectedFeatures.map((feature) => feature.value));
	}, [selectedFeatures]);

	useEffect(() => {
		if (budgetPerDesk === "default" && budgetMinMax !== "none") {
			settingVal(budgetMinMax, "budgetPerDesk");
		}
		if (numberOfPeople === "default" && capacityMinMax !== "none") {
			settingVal(capacityMinMax, "numberOfPeople");
		}
	}, [budgetPerDesk, budgetMinMax, numberOfPeople, capacityMinMax]);

	useEffect(() => {
		const allOffices = dataGlobal.allAirtableProperties.edges;
		const newOffices = allOffices.filter(allFilters);
		setFilteredOffices(newOffices);
	}, [
		selectedOfficeType,
		budgetPerDesk,
		numberOfPeople,
		viewport,
		selectedFeatures,
		distanceAmount,
		dataGlobal.allAirtableProperties.edges,
	]);

	useEffect(() => {
		// Adjust the zoom level based on the distanceAmount
		// You can modify this logic to fine-tune the zoom level
		let newZoom;
		if (distanceAmount <= 0.5) {
			newZoom = 15; // Zoom in closer
		} else if (distanceAmount <= 1) {
			newZoom = 14; // Medium zoom
		} else if (distanceAmount <= 2) {
			newZoom = 13; // Zoom out a bit
		} else {
			newZoom = 12; // Zoom out further
		}

		const updatedViewport = { ...viewport, zoom: newZoom };
		setViewport(updatedViewport);
	}, [distanceAmount, setViewport]); // Re-run the effect when distanceAmount changes

	return (
		<CartContext.Provider
			value={{
				cart,
				numberOfDesksCalculator,
				selectedOfficeType,
				selectedLocation,
				selectedFeatures,
				selectedSimple,
				viewport,
				distancePoint,
				numberOfPeople,
				budgetPerDesk,
				options: [],
				newOptions,
				filteredOffices,
				newServiceOptions,
				newAmenitiesOptions,
				buttonChecked,
				distancePanPoint,
				sortedBy,
				shortOffices,
				distanceAmount,
				idLocation,
				showFilterMobile,
				settingVal,
				onLocationChange,
				onLocationChangeScroll,
				getDistanceFromLatLonInKm,
				OnAddToCart,
				OnRemoveFromCart,
				onLocationChangePan,
				budgetMinMax,
				capacityMinMax,
			}}
		>
			{children}
		</CartContext.Provider>
	);
}
